// auth0 essential file

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    console.log("isAuthenticated: " + isAuthenticated);
    console.log("user: " + user);
    console.log("isLoading " + isLoading);

    return (
        <>
            {isAuthenticated &&
            <div>
                {/*<img src={user.picture} alt={user.name} />*/}
                {/*<h2>{user.name}</h2>*/}
                {/*<p>{user.email}</p>*/}

                <p>hello!</p>
            </div>
            }


        </>
    );
};

export default Profile;