import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import AddPlaydateRqs from "../../models/AddPlaydateRqs";

export const PlaydateNew = () => {

    const { authState } = useOktaAuth();

    // useState arrays
    const [category, setCategory] = useState('Category');
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [veggie, setVeggie] = useState('');

    // Displays
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);

    function categoryField(value: string) {
        setCategory(value);
    }

    async function submitNewItem() {
        const url = `${process.env.REACT_APP_API}/playdate/secure/add/playdate`;
        if (
            authState?.isAuthenticated &&
            // category !== 'Category' &&
            // category !== '' &&
            eventName !== '' &&
            eventDescription !== '' &&
            eventDescription !== ''
        ) {
                const newPlaydate: AddPlaydateRqs = new AddPlaydateRqs(
                    category,
                    eventName,
                    eventDescription,
                    veggie
                );
                // book.img = selectedImage;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newPlaydate)
                };

                const submitNewResponse = await fetch(url, requestOptions);
                if (!submitNewResponse.ok) {
                    throw new Error('Sorry, something went wrong! (Add Playdate 01)');
                }
                setCategory('Category');
                setEventName('');
                setEventDescription('');
                setVeggie('');
                setDisplayWarning(false);
                setDisplaySuccess(true);
            } else {
                setDisplayWarning(true);
                setDisplaySuccess(false);
            }
    }

    return (
        // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
        <div className='pt-5'>
            <div className='container mt-5 mb-5'>
            {displaySuccess && 
                <div className='alert alert-success' role='alert'>
                    Playdate added successfully
                </div>
            }
            {displayWarning && 
                <div className='alert alert-danger' role='alert'>
                    All fields must be filled out, fucker
                </div>
            }
            <div className='card'>
                <div className='card-header'>
                    Add a new Bunny
                </div>
                <div className='card-body'>
                    <form method='POST'>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>eventName</label>
                                <input type="text" className='form-control' name='title' required 
                                    onChange={e => setEventName(e.target.value)} value={eventName} />
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'>eventDescription</label>
                                <input type="text" className='form-control' name='author' required 
                                    onChange={e => setEventDescription(e.target.value)} value={eventDescription}/>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <label className='form-label'>category</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button' 
                                    id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                                        {category}
                                </button>
                                <ul id='addNewBookId' className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                    <li><a onClick={() => categoryField('Apple')} className='dropdown-item'>Apple</a></li>
                                    <li><a onClick={() => categoryField('Banana')} className='dropdown-item'>Banana</a></li>
                                    <li><a onClick={() => categoryField('Currant')} className='dropdown-item'>Currant</a></li>
                                    <li><a onClick={() => categoryField('Dewberry')} className='dropdown-item'>Dewberry</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <label className='form-label'>veggie</label>
                            <textarea className='form-control' id='exampleFormControlTextarea1' rows={3} 
                                onChange={e => setVeggie(e.target.value)} value={veggie}></textarea>
                        </div>
                        {/*<div className='col-md-3 mb-3'>*/}
                        {/*    <label className='form-label'>Copies</label>*/}
                        {/*    <input type='number' className='form-control' name='Copies' required */}
                        {/*        onChange={e => setCopies(Number(e.target.value))} value={copies}/>*/}
                        {/*</div>*/}
                        {/*<input type='file' onChange={e => base64ConversionForImages(e)}/>*/}
                        <div>
                            <button type='button' className='btn btn-primary mt-3' onClick={submitNewItem}>
                                Add Playdate
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
    );
}