// Banana Componenty is the template for entity CRUD management.
// (1) this EU/GUI layout presently is a mess.  get this set up for respectability.
// (2) need to do something with date/time/zone display; needs formatting ("Nov 22" not "11-22") and am/pm stuff.

import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import {Link, useHistory} from "react-router-dom";
import BananaRsp from "../../models/BananaRsp";

export const BananaRecord = () => {
    // realm of the type of object being viewed/managed herein
    const urlRealm = 'bananas';
    // special function to validate authAuth status
    const { authState } = useOktaAuth();
    // enables redirect
    const history = useHistory();
    // captures from browser url the value that uniquely identifies record to be rendered
    const displayId = (window.location.pathname).split('/')[3];
    // object/list to be rendered on page
    const [ bananaRsp, setBananaRsp ] = useState<BananaRsp>();
    // individual field values; keep these: will need to do some formatting on incoming strings for EU acceptance.
    const [ dateString, setDateString ] = useState('');
    const [ timeString, setTimeString ] = useState('')
    const [ timezoneString, setTimezoneString ] = useState('')

    useEffect(() => {
            const fetchBananaRsp = async () => {
                // (1a) instantiate target (aka apiUrl)
                const apiUrl: string = `${process.env.REACT_APP_API}/${urlRealm}/view/?displayId=${displayId}`
                // (1b) instantiate body of stuff that will be sent (aka requestManifest)
                const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                        Accept: "application/json", // JRF: no recall what does / where came from
                        'Content-Type': 'application/json',
                    }
                };
                // (2a) run the fetch function with the inputs shown / listed above, using line below
                const apiResponse = await fetch(apiUrl, requestManifest);
                // (2b) if bogus call being made / api realizes it, redirect to banana list page
                if (!apiResponse.ok) {
                    history.push(`/listo/banana`);
                }
                // (3a) instantiate the JSON response
                const apiResponseJson = await apiResponse.json();
                // (3b) instantiate the model "container" that the response item "fits" into
                const loadedItem: BananaRsp = {
                    name: apiResponseJson.name
                    , email: apiResponseJson.email
                    , category: apiResponseJson.category
                    , locationType: apiResponseJson.locationType
                    , veggie: apiResponseJson.veggie
                    , bananaStatusCodeDisplay: apiResponseJson.bananaStatusCodeDisplay
                    , dateString: apiResponseJson.dateString
                    , timeString: apiResponseJson.timeString
                    , timezoneIdString: apiResponseJson.timezoneIdString
                    , displayId: apiResponseJson.displayId
                    , bananaStatusCode: apiResponseJson.bananaStatusCode
                    // following is for id-based drop-down attribute mgmt
                    // , bananaStatusCodeId: apiResponseJson.bananaStatusCodeId
                };
                // (3c) set flags related to status of function
                setIsLoading(false);
                // (3d) set the useState variable(s) using the instantiated model above
                setBananaRsp(loadedItem);
                setDateString(loadedItem.dateString);
                setTimeString(loadedItem.timeString);
                setTimezoneString(loadedItem.timezoneIdString)
            };
            fetchBananaRsp().catch((error: any) => {
                setIsLoading(false);
                setHttpError(error.message);
            })
    }, []);

    // html for non-instant success scenarios.  NOTE: this should always be right before the html return: if fails, it's a preventative alternative return
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    if (isLoading ) {
        return (
            // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
            <div className='pt-5'>
                <SpinnerLoading/>
            </div>
        )}

    if (httpError) {
        return (
            <div className='container m-5'>
                <p>{httpError}</p>
            </div>
        )}

    return (
        // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
        <div className='pt-5'>
            <div className='container d-none d-lg-block'>
                <div className='row mt-5'>
                    {/*<div className='col-sm-2 col-md-2'>*/}
                    {/*</div>*/}
                    <div className='col-4 col-md-4 container'>

                        <div className='ml-2'>
                            <h1>bananaRsp</h1>
                            <p>name: {bananaRsp?.name}</p>
                            <p>email: {bananaRsp?.email}</p>
                            <p>locationType: {bananaRsp?.locationType}</p>
                            <p>category: {bananaRsp?.category}</p>
                            <p>bananaStatusCodeDisplay: {bananaRsp?.bananaStatusCodeDisplay}</p>
                            <p>dateString: {dateString}  </p>
                            <p>timeString: {timeString}</p>
                            <p>timezoneString: {timezoneString}</p>
                            <p>displayId: {bananaRsp?.displayId}</p>
                            <p>{bananaRsp?.bananaStatusCode}</p>
                        </div>

                    </div>
                </div>
                <hr />
            </div>

            {/*mobile baby*/}
            <div className='container d-lg-none mt-5'>
                <div className='d-flex justify-content-center align-items-center'>
                </div>
                <div className='mt-4'>
                    <div className='col-4 col-md-4 container'>

                        <div className='ml-2'>
                            <h1>bananaRsp</h1>
                            <p>name: {bananaRsp?.name}</p>
                            <p>email: {bananaRsp?.email}</p>
                            <p>locationType: {bananaRsp?.locationType}</p>
                            <p>category: {bananaRsp?.category}</p>
                            <p>bananaStatusCodeDisplay: {bananaRsp?.bananaStatusCodeDisplay}</p>
                            <p>dateString: {dateString}  </p>
                            <p>timeString: {timeString}</p>
                            <p>timezoneString: {timezoneString}</p>
                            <p>displayId: {bananaRsp?.displayId}</p>
                            <p>{bananaRsp?.bananaStatusCode}</p>
                        </div>

                    </div>

                </div>
                <hr />
            </div>

            <Link className='btn btn-md main-color text-white' to={ `/bananas/edit/${displayId}` }>
                Edit
            </Link>
        </div>
    );
}