import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";

export const Heros = () => {

    const { authState } = useOktaAuth();

    return (
        <div>
            <div className='d-none d-lg-block'>
                {/*<div className='row g-0 mt-5'>*/}
                <div className='row g-0'>
                    <div className='col-sm-6 col-md-6'>
                        <div className='col-image-left'></div>
                    </div>
                    <div className='col-4 col-md-4 container d-flex justify-content-center align-items-center'>
                        <div className='ml-2'>
                            <h1>Your spouse just called to remind you... </h1>
                            <p className='lead'>
                                ... that she's going out with friends tonight, so you're on your own with the kids.  You're exhausted, you have no ideas, but you know you don't want to 'parent' alone.  PrestoPLAYDATE is here to help you connect with other parents right now.
                            </p>
                            {authState?.isAuthenticated ? 
                                <Link type='button' className='btn main-color btn-lg text-white'
                                    to='bananas/list'>Create playdate</Link>
                                :
                                <Link className='btn main-color btn-lg text-white' to='/login'>Sign up</Link>
                            }  
                        </div>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-4 col-md-4 container d-flex 
                        justify-content-center align-items-center'>
                        <div className='ml-2'>
                            <h1>Your kids don't need you<br></br>(that much)</h1>
                            <p className='lead'>
                                Being a good parent doesn't mean always engaging your kiddos in endless lessons and play.  Let them explore and play with friends, and give yourself a break.
                                And with help from a playdate parent buddy, you both can help each other help the kids (when they need it).
                            </p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6'>
                        <div className='col-image-right'></div>
                    </div>
                </div>
            </div>

            {/* Mobile Heros */}
            <div className='d-lg-none'>
                <div className='container'>
                    <div className='m-2'>
                        <div className='col-image-left'></div>
                        <div className='mt-2'>
                            <h1>Your spouse just called to remind you... </h1>
                            <p className='lead'>
                                ... that she's going out with friends tonight, so you're on your own with the kids.  You're exhausted, you have no ideas, but you know you don't want to 'parent' alone.  PrestoPLAYDATE is here to help you connect with other parents right now.,
                                we will be able to provide the top content for you!
                            </p>
                            {authState?.isAuthenticated ? 
                                <Link type='button' className='btn main-color btn-lg text-white'
                                    to='bananas/list'>Create playdate</Link>
                                :
                                <Link className='btn main-color btn-lg text-white' to='/login'>Sign up</Link>
                        }
                        </div>
                    </div>
                    <div className='m-2'>
                        <div className='col-image-right'></div>
                        <div className='mt-2'>
                            <h1>Your kids don't need you<br></br>(that much)</h1>
                            <p className='lead'>
                                Being a good parent doesn't mean always engaging your kiddos in endless lessons and play.  Let them explore and play with friends, and give yourself a break.
                                And with help from a playdate parent buddy, you both can help each other help the kids (when they need it).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}