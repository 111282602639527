import { ExploreTopBooks } from "./components/ExploreTopBooks";
import { Heros } from "./components/Heros";

export const HomePage = () => {
    return (
        <>
            <ExploreTopBooks/>
            {/*<Carousel/>*/}
            <Heros/>
            {/*<LibraryServices/>*/}
        </>
    );
}