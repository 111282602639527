class AddPlaydateRqs {
    category: string;
    eventName: string;
    eventDescription: string;
    veggie: string;
    constructor(
        category: string
        , eventName: string
        , eventDescription: string
        , veggie: string
    ) {
            this.category = category;
            this.eventName = eventName;
            this.eventDescription = eventDescription;
            this.veggie = veggie;
        }
}
export default AddPlaydateRqs;