// Banana Componentry is the template for entity CRUD management.
import React, {useEffect, useState} from "react";
import BananaRsp from "../../../models/BananaRsp";
import {Pagination} from "../../Utils/Pagination";
import {SpinnerLoading} from "../../Utils/SpinnerLoading";
import {BananaListoItem} from "./Components/BananaListoItem";
import {Link} from "react-router-dom";
import {useOktaAuth} from "@okta/okta-react";
import CodeRsp from "../../../models/CodeRsp";

export const BananaListo = () => {
    // realm of the type of object being viewed/managed herein
    const urlRealm = 'bananas';
    // special function to validate authAuth status
    const { authState } = useOktaAuth();
    // object/list to be rendered on page
    const [bananaRspList, setBananaRspList] = useState <BananaRsp[]> ([]);
    // pagination variables
    const itemPerPage = 5;
    // const [ itemPerPage, setItemPerPage ] = useState(5) // this  uS for future dev (e.g., enable eu to mge this value)
    const [itemReturnedTotal, setItemReturnedTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    // search variable
    const [searchBox, setSearchBox] = useState('');
    // filter variables
    const [ filterBananaStatusCodeString, setFilterBananaStatusCodeString ] = useState('')
    const [ filterBananaStatusCodeSelection, setFilterBananaStatusCodeSelection] = useState('All Status');

    // search/filter functions... design/explanation:
    // (1) default is display all records: search is blank and filters = all/''
    // (2) eu entering text into the search GUI merely updates searchBox variable above; eu must click "search" button to command the 'searchNow' function that resides below
    // (3) the searchNow function (a) resets the current page to 1 (b) resets the filter(s) to all/'' and (c) flips a heads/tails field so that the uE runs.
    // (4) filterBananaStatusCodeSelector function is a control for the drop-down button gui.  A change to filter will not change the searchBox value.  Change to selector will re-run uE with both whatever search value + filter value
    // (5) reset clears the searchBox, and runs the searchNow function (which as explained above, also resets the filter(s) to all)
    const filterBananaStatusCodeSelectorFn = (code: string, categoryName: string) => {
        setCurrentPage(1);
        setFilterBananaStatusCodeString(code);
        setFilterBananaStatusCodeSelection(categoryName);
    }

    const [searchTrigger, setSearchTrigger] = useState('heads'); // sole purpose of this variable is to be a "flipping coin," that - whenever flipped - triggers uE
    const searchNowFn = () => { // purpose of this function is to trigger re-run of search, and reset the currentPage value (so we'll get the beginning of results of new search).
        setCurrentPage(1);
        filterBananaStatusCodeSelectorFn('', "All Status") // developer sentiment: I just think anytime you do a search, it should reset the filters too.  filtering should always be affecting **results** of the search
        if (searchTrigger === "heads") {
            setSearchTrigger("tails")
        } else {
            setSearchTrigger("heads")
        }
    }

    const resetSearchFn = () => {
        setSearchBox('');
        searchNowFn();
    }

    // bananaStatus: acquire records for drop-down
    // (1) instantiate useState List
    const [bananaStatusCodeList, setBananaStatusCodeList] = useState<CodeRsp[]>([]);
    // (2) run useEffect to populate list
    useEffect(() => {

        const fetchItems = async () => {
            // (2a) instantiate target (aka apiUrl)
            const apiUrl: string = `${process.env.REACT_APP_API}/codes/list/?codeType=playdateStatusType`;
            // (2b) instantiate body of stuff that will be sent (aka requestManifest)
            const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    Accept: "application/json", // JRF: no recall what does / where came from
                    'Content-Type': 'application/json',
                }
            };
            // (2c) run the fetch function with the inputs shown / listed above, using line below
            const apiResponse = await fetch(apiUrl, requestManifest);
            // (2d) instantiate the JSON response
            const apiResponseJson = await apiResponse.json();
            // (2e) instantiate the model "container" that the response items "fit" into, then iterate thru apiResponseJson to package each item from the apiResponseJson, then set the useState list;
            const loadedItems: CodeRsp[] = [];
            for (const key in apiResponseJson) {
                loadedItems.push({
                    code: apiResponseJson[key].code
                    , displayValue: apiResponseJson[key].displayValue
                });
            }
            setBananaStatusCodeList(loadedItems);
        };
        fetchItems().catch((error: any) => {
        })
    }, [
        // react build keeps warning me to include the following, but it's a false directive, don't do it: React Hook useEffect has a missing dependency: 'authState?.accessToken?.accessToken'. Either include it or remove the dependency array

    ]);

    // below is the control that was going to be used, before code-based design implemented
    // const [bananaStatus, setBananaStatus] = useState<number | ''>(1); // this initial state number/integer corresponds to whatever id of the lookup you choose as the default
    // const handleSelectedBananaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const selectedValue = event.target.value;
    //     setBananaStatus(selectedValue === '' ? '' : parseInt(selectedValue, 10));
    // };

    useEffect( () => {
            const fetchBananaRspList = async () => {
                // (1a) establish to-be-pinged url, aka apiUrl
                let apiUrl = `${process.env.REACT_APP_API}/${urlRealm}/list`;
                // (1b) enhance apiUrl to include search parameters
                if (searchBox !== '' && filterBananaStatusCodeString !== '') {  // fyi in below: this 'currentPage -1' stuff is essential, b/c "page 1" is actually page count zero in the api
                    apiUrl += `?page=${currentPage -1}&size=${itemPerPage}&searchBox=${searchBox}&filterBananaStatusCodeString=${filterBananaStatusCodeString} `;

                } else if (searchBox !== '') {
                    apiUrl += `?page=${currentPage -1}&size=${itemPerPage}&searchBox=${searchBox}`;

                } else if (filterBananaStatusCodeString !== '') {
                    apiUrl += `?page=${currentPage -1}&size=${itemPerPage}&filterBananaStatusCodeString=${filterBananaStatusCodeString}`;

                } else {
                    apiUrl += `?page=${currentPage -1}&size=${itemPerPage}`;
                }
                // (1c) create list/package of stuff to be sent, aka manifest
                const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                        Accept: "application/json", // JRF: no recall what does / where came from
                        'Content-Type': 'application/json',
                    }
                };
                // (2) run the fetch function with the inputs shown / listed above, using line below
                const apiResponse = await fetch(apiUrl, requestManifest);
                // (3a) if response error:
                if (!apiResponse.ok) {
                    throw new Error('Something went terribly wrong (BananaList 01)'); // example of how this occurs: connection error w/ BE application; BE application offline; etc.
                }
                // (3b) if good response:
                const apiResponseJson = await apiResponse.json();
                const responseData = apiResponseJson.content;
                const loadedItems : BananaRsp[] = [];
                for (const key in responseData) {
                    loadedItems.push({
                        name: responseData[key].name
                        , email: responseData[key].email
                        , category: responseData[key].category
                        , locationType: responseData[key].locationType
                        , veggie: responseData[key].veggie
                        , bananaStatusCodeDisplay: responseData[key].bananaStatusCodeDisplay
                        , dateString: responseData[key].dateString
                        , timeString: responseData[key].timeString
                        , timezoneIdString: responseData[key].timezoneIdString
                        , displayId: responseData[key].displayId
                        , bananaStatusCode: responseData[key].bananaStatusCode
                        // following is for id-based drop-down attribute mgmt
                        // , bananaStatusCodeId: responseData[key].bananaStatusCodeId
                    });
                }
                setBananaRspList(loadedItems);
                setItemReturnedTotal(apiResponseJson.totalElements);
                setTotalPages(apiResponseJson.totalPages);
                setIsLoading(false);
            };
        fetchBananaRspList().catch( (error: any ) => {
                setIsLoading(false);
                setHttpError(error.message);
            })
            window.scrollTo(0,0) // this means: after useEffect runs, return the page scroll to the top most (and left most, although we don't do left/right scrolling of course)
        }, [ // what's in brackets here means: whenever the variables listed here change, re-run useEffect
            currentPage // essential: if page change (using pagination gui), then rerun and get new results that align to new page
            , searchTrigger
            , filterBananaStatusCodeString
            // react keeps saying to include these, but react is WRONG
            // , authState?.accessToken?.accessToken
            // searchBox
        ]
    ); // end: useEffect

    // html items related to search results (note: these are not used in other search/pagination functions)
    const indexOfLastItem: number = currentPage * itemPerPage; // id of last item being displayed on current page
    const indexOfFirstItem: number = indexOfLastItem - itemPerPage; // id of first item being displayed on current page
    let lastItem =
        itemPerPage * currentPage <= itemReturnedTotal ?
            itemPerPage * currentPage
            : itemReturnedTotal;

    // essential variable for pagination component referenced in html below
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    //  // UE for logging/testing
    // useEffect( () => {
    //         console.log("filterBananaStatusCodeSelection")
    //         console.log(filterBananaStatusCodeSelection)
    //         console.log("filterBananaStatusCodeString")
    //         console.log(filterBananaStatusCodeString)
    //     } ,
    //     [
    //         filterBananaStatusCodeString
    //         , filterBananaStatusCodeSelection
    //     ]
    // )

    // html for non-instant success scenarios.  NOTE: this must always be last code section before the big/main/successful file return.  If it's positioned higher, the react build will fail.
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    if (isLoading) {
        return (
            // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
            <div className='pt-5'>
                <SpinnerLoading/>
            </div>
        )
    }
    if (httpError) {
        return (
            <div className='container m-5'>
                <p>{httpError}</p>
            </div>
        )
    }
    return (
        // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
        <div className='pt-5'>
            <div id='reactPageContainer' className='container mt-3' >
                <div id='searchControls' className='container pb-1' >
                    <div className='row' >
                        <div id='searchBox' className='col-sm-4 mt-2 ' >
                                <input
                                    className='form-control me-2'
                                    type='search'
                                    placeholder='search playdates'
                                    aria-labelledby='searchBox'
                                    onChange = {e => setSearchBox(e.target.value)}
                                    value={searchBox}
                                />
                        </div>

                        <div id='searchCommands' className=' col-sm-2 ' >
                            <div className='row' >
                                <div id='search' className='col-6 mt-2  d-grid gap-1 btn-group ' >
                                    <button
                                        className='btn btn-outline-success '
                                        onClick={() => searchNowFn()}>
                                        Search
                                    </button>
                                </div>

                                <div id='reset' className=' col-6 mt-2  d-grid gap-2 btn-group ' >
                                    <button
                                        className='btn btn-outline-secondary '
                                        onClick={() => resetSearchFn()}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div id='resultsFilter1' className='col-sm-6 mt-2 d-flex ' >

                            <div className=' d-flex align-items-center'>
                                {/*below "m-0" classname is required on p tags, to remove the default bottom margin on <p></p>*/}
                                <p className='m-0 fst-italic text-secondary'>Filter results:</p>
                            </div>

                            <div className=' d-flex align-items-center'>
                                {/*for below: https://getbootstrap.com/docs/5.0/components/dropdowns/*/}
                                <div className='dropdown'>
                                    <button
                                        className='btn btn-secondary dropdown-toggle ms-1'
                                        type='button'
                                        id='dropdownMenuButton1'
                                        data-bs-toggle='dropdown'
                                        aria-expanded='false'>
                                        {filterBananaStatusCodeSelection}
                                    </button>
                                    <ul
                                        className='dropdown-menu pt-0'
                                        aria-labelledby='dropdownMenuButton1'
                                    >
                                        {/*design clarification:
                                        (1) the "code" (variable below) must be that which will get purportedly get a match in codes db table;
                                        (2) categoryName and the display must always be the same, or you'll get GUI issues between drop-down vs. text inside the button*/}
                                        {/*leave first li as-is: a catch-all / get-all value*/}
                                        <li className='dropdown-item '
                                            onClick={() => filterBananaStatusCodeSelectorFn('', 'All Status')}>All Status
                                        </li>
                                        {bananaStatusCodeList.map(item => (
                                            <li className='dropdown-item'
                                                onClick={() => filterBananaStatusCodeSelectorFn(`${item.code}`, `${item.displayValue}`)}
                                            >{item.displayValue}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                {/*the following html/content is "unbound" by bootstrap/grid, other than btstp styling that is contained in the component file */}
                { itemReturnedTotal > 0 ?
                    <>
                        <div className='mt-3'>
                            <p><span className='h5'>{itemReturnedTotal} playdates found</span> <span className='fst-italic'> (showing items {indexOfFirstItem +1} to {lastItem}) </span></p>
                        </div>

                        {bananaRspList.map(item => (
                            <BananaListoItem item={item} key={item.displayId}/>
                        ))}
                    </>
                    :
                    <div  className='mt-3'>
                        <h4>Looks like there are no playdates matching the above criteria.</h4>
                        <h5>You can click "Reset" to display all playdates again.  Or, enter new searches / filters to perform a new search.</h5>
                        <h5>Click "New Banana" to begin creating a new playdate.</h5>
                    </div>
                }

                <div id='paginationAndNew' className='row my-2 ' >

                    <div id='paginationStation' className=' col-sm-4 d-grid justify-content-center' >
                            {totalPages > 1 && // double ampersand here means: "then do this..." which might seem confusing, but just go with it.
                                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}/>
                            }
                    </div>

                    <div id='createNewStation' className='col-sm-4 d-grid justify-content-center' >

                        <Link to='/bananas/add'
                              type='button'
                              className='btn main-color btn-lg px-4 me-md-2 fw-bold text-white'
                        >
                            New Banana
                        </Link>
                    </div>

                    <div id='spaceHolder' className=' col-sm-4 ' >
                    </div>
                </div>
            </div>

            {/*<div id='listOfCodes'>*/}
            {/*    <div className="row">*/}
            {/*        <h3>listOfCodes: bananaStatus</h3>*/}
            {/*        {bananaStatusCodeList.map(item => (*/}
            {/*            <div className='col-sm-8 col-md-8'>*/}
            {/*                <p>{item.code}: {item.displayValue}</p>*/}
            {/*                <hr/>*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}