// Banana Componenty is the template for entity CRUD management.
// (2) look into retiring the 'old version' for drop-down select
// (3) radio selects
// (4) number fields

import {useOktaAuth} from "@okta/okta-react";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import CodeRsp from "../../../models/CodeRsp";
import TimezoneRsp from "../../../models/TimezoneRsp";
import BananaRsp from "../../../models/BananaRsp";
import BananaRqs from "../../../models/BananaRqs";
import {SpinnerLoading} from "../../Utils/SpinnerLoading";

export const ManageBanana: React.FC<{
    createNewPath: Boolean
    , displayId: String
}> = (props, key) => {

    // realm of the type of object being viewed/managed herein
    const urlRealm = 'bananas';
    // special function to validate authAuth status
    const { authState } = useOktaAuth();
    // object/list to be rendered on page
    const [ bananaRsp, setBananaRsp ] = useState<BananaRsp>();
    // form fields: every attribute of the object to be managed in the form needs its own useState variable; real/used defaults for these variables are set with UE fetch further down
    const [name, setName] = useState  ('');
    const [email, setEmail] = useState('');
    const [locationType, setLocationType] = useState('')
    const [category, setCategory] = useState('');
    const [ veggie, setVeggie ] = useState( '');
    const [ bananaStatusCode, setBananaStatusCode ] = useState('')
    const [ dateString, setDateString ] = useState('');
    const [ timeString, setTimeString ] = useState('')
    const [ timezoneString, setTimezoneString ] = useState('')
    // error msg fields:
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [locationTypeError, setLocationTypeError] = useState('')
    const [categoryError, setCategoryError] = useState('');
    const [bananaStatusError, setBananaStatusError] = useState('');
    const [eventDateError, setEventDateError] = useState('');
    const [eventTimeError, setEventTimeError] = useState('');
    const [timezoneError, setTimezoneError] = useState('');
    // following is for id-based drop-down attribute mgmt
    // const [bananaStatus, setBananaStatus] = useState<number | ''>(1); // this initial state number/integer corresponds to whatever id of the lookup you choose as the default

    // open question is to what extent if any these "handleEventChangeX" is useful.  Def helpful during dev for setting test variables, but feels like overkill otherwise.
    const handleEventDateChangeFn = (event: React.ChangeEvent<HTMLDataElement>) => {
        const enteredValue = event.target.value;
        setDateString(enteredValue === '' ? '' : enteredValue );
    };
    const handleTimeChangeFn = (event: React.ChangeEvent<HTMLDataElement>) => {
        const enteredValue = event.target.value;
        setTimeString(enteredValue === '' ? '' : enteredValue + ':00');
    };

    // stringifyJsDateDate and stringifyJsDateTime used to create default values, in string format, for date and time, so that date/time in form can be set to a default value (EU browser's "now" time, in this case)
    const stringifyJsDateDateFn = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const stringifyJsDateTimeFn = (date: Date): string => {
        const hour = String(date.getHours()).padStart(2, '0');
        // placeholder for if/else function that rounds to nearest half-hour or quarter hour, if decide we need that.
        // below rounds 'now' to downward nearest hour
        return `${hour}:00:00`;
    };
    
    // set as-is values (edit path) or preset values (createNew path) for form fields
    useEffect(() => {
        if (!props.createNewPath) { // this 'ifs' this whole thing: it prevents this uE from running (and failing) the fetch on createNew path; defines alt action for uE for createNew
            const fetchBananaRsp = async () => {
                // (1a) instantiate target (aka apiUrl)
                const apiUrl: string = `${process.env.REACT_APP_API}/${urlRealm}/view/?displayId=${props.displayId}`
                // (1b) instantiate body of stuff that will be sent (aka requestManifest)
                const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                        Accept: "application/json", // JRF: no recall what does / where came from
                        'Content-Type': 'application/json',
                    }
                };
                // (2a) run the fetch function with the inputs shown / listed above, using line below
                const apiResponse = await fetch(apiUrl, requestManifest);
                // (2b) this redirect is essential, b/c this error can occur is the displayId being used here is bogus, e.g., /bananas/edit/holyCow
                if (!apiResponse.ok) {history.push(`/${urlRealm}/list`);}
                // (2c) instantiate the JSON response
                const apiResponseJson = await apiResponse.json();
                // (3a) instantiate the model "container" that the response item "fits" into
                const loadedItem: BananaRsp = {
                    name: apiResponseJson.name
                    , email: apiResponseJson.email
                    , category: apiResponseJson.category
                    , locationType: apiResponseJson.locationType
                    , veggie: apiResponseJson.veggie
                    , dateString: apiResponseJson.dateString
                    , timeString: apiResponseJson.timeString
                    , timezoneIdString: apiResponseJson.timezoneIdString
                    , displayId: apiResponseJson.displayId
                    , bananaStatusCode: apiResponseJson.bananaStatusCode
                    , bananaStatusCodeDisplay: apiResponseJson.bananaStatusCodeDisplay // this is essential for display in record page, so need it here too
                    // following is for id-based drop-down attribute mgmt
                    // , bananaStatusCodeId: apiResponseJson.bananaStatusCodeId
                };
                // (3b) set flags related to status of function
                setIsLoading(false);
                // (3c) set the useState variable(s) using the instantiated model above
                setBananaRsp(loadedItem); // this is used to display as-was values on the page, for edit form testing
                // fyi, below is the big way how this herein fetch differs from BananaRecord: below we are setting all those variables which get managed by the form.
                setName(loadedItem.name);
                setEmail(loadedItem.email);
                setLocationType(loadedItem.locationType);
                setCategory(loadedItem.category);
                setDateString(loadedItem.dateString);
                setTimeString(loadedItem.timeString);
                setTimezoneString(loadedItem.timezoneIdString)
                setBananaStatusCode(loadedItem.bananaStatusCode)
                // following is for id-based drop-down attribute mgmt
                // setBananaStatus(loadedItem.bananaStatusCodeId)
            };
            fetchBananaRsp().catch((error: any) => {
                setIsLoading(false);
                setHttpError(error.message);
            })

        } else {
            // placeholder for setting other defaults
            setDateString ( stringifyJsDateDateFn(new Date()) )
            setTimeString ( stringifyJsDateTimeFn(new Date()) )
            setTimezoneString('America/Chicago') // is there a way to capture EU timeZone?  look into this
            setLocationType('1')
            setCategory('0')
            setBananaStatusCode('occurring')
            setIsLoading(false);

        }
    }, []);

    // enable "old version" of form-select
    const [selectedLocationTypeOption, setSelectedLocationTypeOption] = useState<String>();
    const [selectedCategoryOption, setSelectedCategoryOption] = useState<String>();
    const selectChangeLocationTypeFn = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedLocationTypeOption(value);
        setLocationType(value)
    };
    const selectChangeCategoryFn = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedCategoryOption(value);
        setCategory(value)
    };

    // following is for id-based drop-down attribute mgmt
    // const handleSelectedBananaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     const selectedValue = event.target.value;
    //     setBananaStatus(selectedValue === '' ? '' : parseInt(selectedValue, 10));
    // };

    // enable "new version" of radios/toggles/dropdowns (no "selected.." useState variables)
    const handleSelectBananaStatusFn = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setBananaStatusCode(selectedValue);
    };

    const handleSelectedTimezoneFn = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setTimezoneString(selectedValue );
    };

    // bananaStatus: acquire valid records for form's select drop-down
    // (1) instantiate useState List
    const [bananaStatusCodeList, setBananaStatusCodeList] = useState<CodeRsp[]>([]);
    // (2) run useEffect to populate list
    useEffect(() => {
        const fetchItems = async () => {
            // (a) instantiate target (aka apiUrl)
            const apiUrl: string = `${process.env.REACT_APP_API}/codes/list/?codeType=playdateStatusType`;
            // (b) instantiate body of stuff that will be sent (aka requestManifest)
            const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    Accept: "application/json", // JRF: no recall what does / where came from
                    'Content-Type': 'application/json',
                }
            };
            // (c) run the fetch function with the inputs shown / listed above, using line below
            const apiResponse = await fetch(apiUrl, requestManifest);
            // (d) instantiate the JSON response
            const apiResponseJson = await apiResponse.json();
            // (e) instantiate the model "container" that the response items "fit" into, then iterate thru apiResponseJson to package each item from the apiResponseJson, then set the useState list;
            const loadedItems: CodeRsp[] = [];
            for (const key in apiResponseJson) {
                loadedItems.push({
                    code: apiResponseJson[key].code
                    , displayValue: apiResponseJson[key].displayValue
                    // following is for id-based drop-down attribute mgmt
                    // id: apiResponseJson[key].id
                    // ,
                });
            }
            setBananaStatusCodeList(loadedItems);
        };
        fetchItems().catch((error: any) => {
        })
    }, []);

    // timezoneString: acquire valid records for form's select drop-down
    // (1) instantiate useState List
    const [timezoneRspList, setTimezoneRspList] = useState<TimezoneRsp[]>([]);
    // (2) run useEffect to populate list
    useEffect(() => {
        const fetchItems = async () => {
            // (a) instantiate target (aka apiUrl)
            const apiUrl: string = `${process.env.REACT_APP_API}/timezones/list`;
            // (b) instantiate body of stuff that will be sent (aka requestManifest)
            const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    Accept: "application/json", // JRF: no recall what does / where came from
                    'Content-Type': 'application/json',
                }
            };
            // (c) run the fetch function with the inputs shown / listed above, using line below
            const apiResponse = await fetch(apiUrl, requestManifest);
            // (d) instantiate the JSON response
            const apiResponseJson = await apiResponse.json();
            // (e) instantiate the model "container" that the response items "fit" into, then iterate thru apiResponseJson to package each item from the apiResponseJson, then set the useState list;
            const loadedItems: TimezoneRsp[] = [];
            for (const key in apiResponseJson) {
                loadedItems.push({
                    zoneText: apiResponseJson[key].zoneText
                    , displayOrder: apiResponseJson[key].displayOrder
                });
            }
            setTimezoneRspList(loadedItems);
        };
        fetchItems().catch((error: any) => {
        })
    }, []);
    // banner control attribute
    const [responseBannerType, setResponseBannerType] = useState(0);
    // enable redirect
    const history = useHistory();

    // createNew path: function to handle submit
    const addBananaFn = async (e: { preventDefault: () => void; }) => {
        // (1) prevent page refresh on submit button; not sure if this is needed with new button design (??)
        e.preventDefault();
        // (2) reset error msgs to blank, so that clean slate for error-v-noError on the next submission
        setNameError('');
        setEmailError('');
        setCategoryError('');
        setLocationTypeError('');
        setBananaStatusError('');
        setEventDateError('');
        setEventTimeError('');
        setTimezoneError('');
        // (3a) instantiate target (aka apiUrl)
        const apiUrl = `${process.env.REACT_APP_API}/${urlRealm}/add`
        // (3b) instantiate body of stuff that will be sent (aka toBeObject & requestManifest)
        const toBeObject: BananaRqs = new BananaRqs(
            name
            , email
            , locationType
            , category
            , veggie
            , dateString
            , timeString
            , timezoneString
            , bananaStatusCode
            // following is for id-based drop-down attribute mgmt
            // , bananaStatus
        );
        const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                Accept: "application/json", // JRF: no recall what does / where came from
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(toBeObject),
        };
        // (4a) run the fetch function with the inputs shown / listed above, using line below
        const apiResponse = await fetch(apiUrl, requestManifest);
        // (x) leave below commOUT: it's checking if response not okay, but this is already handled by checking response status in lines that follow
        // if (!apiResponse.ok) {history.push(`/bananas/list`);}
        // (4b) instantiate the JSON response
        const apiResponseJson = await apiResponse.json();
        // (5a) if response has errors...
        if (apiResponse.status === 400) {
            // (5a1) iterate through the response sub objects with forEach
            apiResponseJson.forEach((fieldError: {
                field: string;
                defaultMessage: React.SetStateAction<string>;
                code: React.SetStateAction<string>;
            }) => {
                // list here: an 'if' statement for each field in this form/method
                if(fieldError.field === 'email'){
                    setEmailError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'name'){
                    setNameError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'category'){
                    setCategoryError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'locationType'){
                    setLocationTypeError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'dateString'){
                    setEventDateError(fieldError.defaultMessage)
                }
                if(fieldError.field === 'timeString'){
                    setEventTimeError(fieldError.defaultMessage)
                }
                if(fieldError.field === 'timezoneString'){
                    setTimezoneError(fieldError.defaultMessage)
                }
                if(fieldError.field === 'bananaStatusCode'){
                    setBananaStatusError(fieldError.defaultMessage);
                }
            });
            // (5a2) set failSuccess field
            setResponseBannerType(2);
            // (5b) if response NO has errors... (which inherently means record created/saved)
        } else if (apiResponse.status === 200) {
            // Option 1: don't redirect, just reset the form and give message
            // setResponseBannerType(3);
            // // various fields' setters
            // Option 2: redirect to newly created record (and skip all that form resetting)
            history.push(`/${urlRealm}/view/${apiResponseJson.displayId}`);
        }
    };

    // editPath: function to handle submit
    const updateBananaFn = async (e: { preventDefault: () => void; }) => {
        // (1) prevent page refresh on submit button
        e.preventDefault();
        // (2) reset error msgs to blank, so that clean slate for error-v-noError on the next submission
        setNameError('');
        setEmailError('');
        setCategoryError('');
        setLocationTypeError('');
        setBananaStatusError('');
        setEventDateError('');
        setEventTimeError('');
        setTimezoneError('');
        // (3a) instantiate target (aka apiUrl)
        const apiUrl = `${process.env.REACT_APP_API}/${urlRealm}/edit/?displayId=${props.displayId}`
        // (3b) instantiate body of stuff that will be sent (aka toBeObject & requestManifest)
        const toBeObject: BananaRqs = new BananaRqs(
            name
            , email
            , locationType
            , category
            , veggie
            , dateString
            , timeString
            , timezoneString
            , bananaStatusCode
            // following is for id-based drop-down attribute mgmt
            // , bananaStatus
        );
        const requestManifest = { // https://www.merriam-webster.com/dictionary/manifest
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                Accept: "application/json", // JRF: no recall what does / where came from
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(toBeObject),
        };
        // (4a) run the fetch function with the inputs shown / listed above, using line below
        const apiResponse = await fetch(apiUrl, requestManifest);
        // (4b) leave below commOUT: it's checking if response not okay, but this is already handled by checking response status in lines that follow
        // if (!apiResponse.ok) {history.push(`/bananas/list`);}
        // (4c) instantiate the JSON response
        const apiResponseJson = await apiResponse.json();
        // (5a) if response has errors...
        if (apiResponse.status === 400) {
            // (5a1) iterate through the response sub objects with forEach
            apiResponseJson.forEach((fieldError: {
                field: string;
                defaultMessage: React.SetStateAction<string>;
                code: React.SetStateAction<string>;
                }) => {
                // list here: an 'if' statement for each field in this form/method
                if(fieldError.field === 'email'){
                    setEmailError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'name'){
                    setNameError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'category'){
                    setCategoryError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'locationType'){
                    setLocationTypeError(fieldError.defaultMessage);
                }
                if(fieldError.field === 'dateString'){
                    setEventDateError(fieldError.defaultMessage)
                }
                if(fieldError.field === 'timeString'){
                    setEventTimeError(fieldError.defaultMessage)
                }
                if(fieldError.field === 'timezoneString'){
                    setTimezoneError(fieldError.defaultMessage)
                }
                if(fieldError.field === 'bananaStatusCode'){
                    setBananaStatusError(fieldError.defaultMessage);
                }
            });
            // (5a2) set failSuccess field
            setResponseBannerType(2);
            // (5b) if response NO has errors...
        } else if (apiResponse.status === 200) {
            // redirect to newly created record (and skip all that form resetting)
            history.push(`/${urlRealm}/view/${apiResponseJson.displayId}`);
        }
    };

    // CONSOLE LOG RESULTS OF VARIOUS FOR EDITS (FOR TESTING)
    useEffect( () => {
            // console.log("placeholder")
        } ,
        [
            // fields, which - if edited - you want to see logged
        ]
    )

    // begin: html for non-instant success scenarios.  NOTE: this should always be right before the html return: if fails, it's a preventative alternative return
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    if ( isLoading ) {
        return (
            // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
            <div className='pt-5'>
                <SpinnerLoading/>
            </div>
        )}

    if (httpError) {
        return (
            <div className='container m-5'>
                <p>{httpError}</p>
            </div>
        )}

    return (
        // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
        <div className='pt-5'>
            {/*DoNotDelete; commentOnOff.  Below is for editPath: testing that incoming record looks okay, regardless of form.*/}
            {/*{ !props.createNewPath ?*/}
            {/*    <div className='container d-none d-lg-block'>*/}
            {/*        <div className='row mt-5'>*/}
            {/*            <div className='col-sm-2 col-md-2'>*/}
            {/*            </div>*/}
            {/*            <div className='container'>*/}
            {/*                <div className='row'>*/}
            {/*                    <div className='col'>*/}
            {/*                        <h1>record in db </h1>*/}
            {/*                        <p>bananaRsp?.bananaStatusCode: {bananaRsp?.bananaStatusCode} </p>*/}
            {/*                    </div>*/}
            {/*                    <div className='col'>*/}
            {/*                        <h1>record per form</h1>*/}
            {/*                        <p>bananaStatusCode (variable here): {bananaStatusCode} </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <hr />*/}
            {/*    </div>*/}
            {/*    :*/}
            {/*    <></>*/}
            {/*}*/}
            
            <div className='container mt-5 mb-5'>
                {responseBannerType === 2 &&
                    <div className='alert alert-danger' role='alert'>
                        Whoa... problem!  Banana not saved.  Please resolve errors shown below.
                    </div>
                }
                <div className='card'>
                    {props.createNewPath ?
                    <div className='card-header'>
                        Create new banana
                    </div>
                    :
                    <div className='card-header'>
                        Edit this banana
                    </div>
                    }
                    <div className='card-body py-2'>

                        <div className='row mb-2 py-0 px-3'>
                            <div id='spaceHolder' className=' col-sm-11 ' >
                            </div>
                            <div id='cancelButtonHolder' className=' col-sm-1 d-grid gap-1 btn-group' >
                            { props.createNewPath ?
                                <>
                                    <Link className='btn btn-outline-secondary' to={ `/bananas/list` }>
                                        Cancel
                                    </Link>
                                </>
                                :
                                <>
                                    <Link className='btn btn-outline-secondary' to={ `/bananas/view/${props.displayId}` }>
                                        Cancel
                                    </Link>
                                </>
                            }
                            </div>
                        </div>

                        <form >
                            <div className='row'>
                                <div id='eventDate' className="form-floating col-md-4 mb-3">
                                    {eventDateError ?
                                        <input
                                            type="date"
                                            className="form-control is-invalid"
                                            id="eventDate"
                                            name="eventDate"
                                            placeholder="eventDate"
                                            value={dateString}
                                            onChange={handleEventDateChangeFn}
                                        />
                                        :
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="eventDate"
                                            name="eventDate"
                                            placeholder="eventDate"
                                            value={dateString}
                                            onChange={handleEventDateChangeFn}
                                        />
                                    }
                                    <label
                                        className='ps-4' // ensures proper left side spacing on label. this wasn't required before, not sure why now.
                                        // htmlFor="name" // this doesn't do anything.  should be .. for="name" ... but reactTS won't accept that.  Moving on.
                                    >eventDate
                                    </label>
                                    {eventDateError ? <p className="text-danger">{eventDateError}</p>  : ''}
                                </div>

                                <div id='eventTime' className="form-floating col-md-4 mb-3">
                                    {eventTimeError ?
                                        <input
                                            type="time"
                                            className="form-control is-invalid"
                                            id="timeString"
                                            name="timeString"
                                            placeholder="timeString"
                                            value={timeString}
                                            onChange={handleTimeChangeFn}
                                        />
                                        :
                                        <input
                                            type="time"
                                            className="form-control"
                                            id="timeString"
                                            name="timeString"
                                            placeholder="timeString"
                                            value={timeString}
                                            onChange={handleTimeChangeFn}
                                        />
                                    }
                                    <label
                                        className='ps-4' // ensures proper left side spacing on label. this wasn't required before, not sure why now.
                                    >eventTime
                                    </label>
                                    {eventTimeError ? <p className="text-danger">{eventTimeError}</p>  : ''}
                                </div>

                                <div className='form-floating col-md-4 mb-3 '>
                                    {
                                        timezoneError ?
                                            <select
                                                className="form-select is-invalid"
                                                id="numberSelect"
                                                name="numberSelect"
                                                value={timezoneString} // this is the line that keeps the select after validation errors
                                                onChange={handleSelectedTimezoneFn}
                                            >
                                                <option value="">Select timezone</option>

                                                {timezoneRspList.map((option) => (
                                                    <option key={option.zoneText} value={option.zoneText}>
                                                        {option.zoneText}
                                                    </option>
                                                ))}
                                            </select>
                                            :
                                            <select
                                                className="form-select"
                                                id="numberSelect"
                                                name="numberSelect"
                                                value={timezoneString} // this is the line that keeps the select after validation errors
                                                onChange={handleSelectedTimezoneFn}
                                            >
                                                <option value="">Select timezone</option>

                                                {timezoneRspList.map((option) => (
                                                    <option key={option.zoneText} value={option.zoneText}>
                                                        {option.zoneText}
                                                    </option>
                                                ))}
                                            </select>
                                    }
                                    <label className='ps-4'>bananaStatus</label>
                                    {timezoneError ? <p className="text-danger">{timezoneError}</p>  : ''}
                                </div>
                            </div>

                            {/*following is for id-based drop-down attribute mgmt*/}

                            {/*<div className='row'>*/}
                            {/*    <div id='bananaStatus' className='form-floating col-md-6 mb-3 '>*/}
                            {/*        {*/}
                            {/*            bananaStatusError ?*/}
                            {/*                <select*/}
                            {/*                    className="form-select is-invalid"*/}
                            {/*                    id="numberSelect"*/}
                            {/*                    name="numberSelect"*/}
                            {/*                    value={bananaStatus} // this is the line that keeps the select after validation errors*/}
                            {/*                    onChange={handleSelectedBananaChange}*/}
                            {/*                >*/}
                            {/*                    <option value="">Select an option</option>*/}

                            {/*                    {bananaStatusCodeList.map((option) => (*/}
                            {/*                        <option key={option.id} value={option.id.toString()}>*/}
                            {/*                            {option.displayValue}*/}
                            {/*                        </option>*/}
                            {/*                    ))}*/}
                            {/*                </select>*/}
                            {/*                :*/}
                            {/*                <select*/}
                            {/*                    className="form-select"*/}
                            {/*                    id="numberSelect"*/}
                            {/*                    name="numberSelect"*/}
                            {/*                    value={bananaStatus} // this is the line that keeps the select after validation errors*/}
                            {/*                    onChange={handleSelectedBananaChange}*/}
                            {/*                >*/}
                            {/*                    <option value="">Select an option</option>*/}

                            {/*                    {bananaStatusCodeList.map((option) => (*/}
                            {/*                        <option key={option.id} value={option.id.toString()}>*/}
                            {/*                            {option.displayValue}*/}
                            {/*                        </option>*/}
                            {/*                    ))}*/}
                            {/*                </select>*/}
                            {/*        }*/}
                            {/*        <label className='ps-4'>bananaStatus</label>*/}
                            {/*        {bananaStatusError ? <p className="text-danger">{bananaStatusError}</p>  : ''}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*below is new code-based bananaStatus mgmt*/}

                            <div className='row'>
                                <div id='bananaStatus' className='form-floating col-md-6 mb-3 '>
                                    {
                                        bananaStatusError ?
                                            <select
                                                className="form-select is-invalid"
                                                id="bananaStatusSelect"
                                                name="bananaStatusSelect"
                                                value={bananaStatusCode} // this is the line that keeps the select after validation errors
                                                onChange={handleSelectBananaStatusFn}
                                            >
                                                <option value="">Select an option</option>

                                                {bananaStatusCodeList.map((option) => (
                                                    <option key={option.code} value={option.code}>
                                                        {option.displayValue}
                                                    </option>
                                                ))}
                                            </select>
                                            :
                                            <select
                                                className="form-select"
                                                id="bananaStatusSelect"
                                                name="bananaStatusSelect"
                                                value={bananaStatusCode} // this is the line that keeps the select after validation errors
                                                onChange={handleSelectBananaStatusFn}
                                            >
                                                <option value="">Select an option</option>

                                                {bananaStatusCodeList.map((option) => (
                                                    <option key={option.code} value={option.code}>
                                                        {option.displayValue}
                                                    </option>
                                                ))}
                                            </select>
                                    }
                                    <label className='ps-4'>bananaStatus</label>
                                    {bananaStatusError ? <p className="text-danger">{bananaStatusError}</p>  : ''}
                                </div>
                            </div>

                            <div className='row'>
                                <div id='name' className="form-floating col-md-6 mb-3">
                                    {
                                        nameError ?
                                            <input
                                                type="text"
                                                className="form-control is-invalid"
                                                id="name"
                                                name="name"
                                                placeholder="name"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                            :
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="name"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                            />
                                    }
                                    <label
                                        className='ps-4' // ensures proper left side spacing on label. this wasn't required before, not sure why now.
                                    >Name
                                    </label>
                                    <div id="nameHelp" className="form-text">This is a helpful note about how to complete this field.</div>
                                    {nameError ? <p className="text-danger">{nameError}</p>  : ''}
                                </div>

                                <div id='email'  className="form-floating col-md-6 mb-3">
                                    {emailError ?
                                        <input
                                            type="text"
                                            className="form-control is-invalid"
                                            id="email"
                                            name="email"
                                            placeholder="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        :
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    }
                                    <label
                                        className='ps-4' // ensures proper left side spacing on label. this wasn't required before, not sure why now.
                                    >Email
                                    </label>
                                    {emailError ? <p className="text-danger">{emailError}</p>  : ''}
                                </div>

                                <div id='locationType' className='form-floating col-md-6 mb-3'>
                                    {locationTypeError ?
                                        <select
                                            onChange={selectChangeLocationTypeFn}
                                            className="form-select is-invalid"
                                            id="locationType"
                                            name="locationType"
                                            aria-label="Floating label select example"
                                        >
                                            {  locationType === "0" ?
                                                <option selected value="0">(none)</option>
                                                :
                                                <option value="0">(none)</option>
                                            }

                                            {  locationType === "1" ?
                                                <option selected value="1">yo place</option>
                                                :
                                                <option value="1">yo place</option>
                                            }

                                            {  locationType === "2" ?
                                                <option selected value="2">my crib</option>
                                                :
                                                <option value="2">my crib</option>
                                            }
                                        </select>
                                        :
                                        <select
                                            onChange={selectChangeLocationTypeFn}
                                            className="form-select"
                                            id="locationType"
                                            name="locationType"
                                            aria-label="Floating label select example"
                                        >
                                            {  locationType === "0" ?
                                                <option selected value="0">(none)</option>
                                                :
                                                <option value="0">(none)</option>
                                            }

                                            {  locationType === "1" ?
                                                <option selected value="1">yo place</option>
                                                :
                                                <option value="1">yo place</option>
                                            }

                                            {  locationType === "2" ?
                                                <option selected value="2">my crib</option>
                                                :
                                                <option value="2">my crib</option>
                                            }
                                        </select>
                                    }
                                    <label className='ps-4'>locationType</label>
                                    {locationTypeError ? <p className="text-danger">{locationTypeError}</p>  : ''}
                                </div>
                            </div>

                            <div className='row'>
                                {  locationType === "2" ?
                                    <div className='form-floating col-md-6 mb-3 '>
                                        {categoryError ?
                                            <select
                                                onChange={selectChangeCategoryFn}
                                                className="form-select is-invalid"
                                                id="category"
                                                name="category"
                                                aria-label="Floating label select example"
                                            >
                                                {  category === "0" ?
                                                    <option selected value="0">(none)</option>
                                                    :
                                                    <option value="0">(none)</option>
                                                }

                                                {  category === "1" ?
                                                    <option selected value="1">FrontEnd</option>
                                                    :
                                                    <option value="1">FrontEnd</option>
                                                }

                                                {  category === "2" ?
                                                    <option selected value="2">BackEnd</option>
                                                    :
                                                    <option value="2">BackEnd</option>
                                                }
                                            </select>
                                            :
                                            <select
                                                onChange={selectChangeCategoryFn}
                                                className="form-select"
                                                id="category"
                                                name="category"
                                                aria-label="Floating label select example"
                                            >
                                                {  category === "0" ?
                                                    <option selected value="0">(none)</option>
                                                    :
                                                    <option value="0">(none)</option>
                                                }

                                                {  category === "1" ?
                                                    <option selected value="1">FrontEnd</option>
                                                    :
                                                    <option value="1">FrontEnd</option>
                                                }

                                                {  category === "2" ?
                                                    <option selected value="2">BackEnd</option>
                                                    :
                                                    <option value="2">BackEnd</option>
                                                }
                                            </select>
                                        }
                                        <label className='ps-4'>Category</label>
                                        {categoryError ? <p className="text-danger">{categoryError}</p>  : ''}
                                    </div>
                                    :
                                    // no need immediately below for entire repeat of above with a 'collapse' in the top classname. an empty open/close tag will work.
                                    <></>
                                }
                            </div>

                            <div id='addUpdateStation' className='row my-2 ' >

                                <div id='spaceHoldera' className=' col-sm-4' >
                                </div>

                                <div id='createNewStation' className='col-sm-4 d-grid gap-1 btn-group justify-content-center' >
                                    { props.createNewPath ?
                                        <div>
                                            <button type="button" onClick={addBananaFn}className='btn main-color text-white'>addBanana</button>
                                        </div>
                                    :
                                        <div>
                                            <button type="button" onClick={updateBananaFn} className='btn main-color text-white'>Update</button>
                                        </div>
                                    }
                                </div>

                                <div id='spaceHolderb' className=' col-sm-4 ' >
                                </div>
                            </div>

                        </form>

                        {/*DoNotDelete; commentOnOff.  Below helpful for front-end testing of drop-downs prior to submission*/}

                        {/*<div id='listOfCodes'>*/}
                        {/*    <div className="row">*/}
                        {/*        <h3>listOfCodes: bananaStatus</h3>*/}
                        {/*        {bananaStatusCodeList.map(item => (*/}
                        {/*            <div className='col-sm-8 col-md-8'>*/}
                        {/*                <p>{item.id}: {item.displayValue}</p>*/}
                        {/*                <hr/>*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </div>
    )
} // end ManageBanana