import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {Auth0Provider} from "@auth0/auth0-react";

// // below = DEVELOPMENT VARIABLES (test publishable key)
// const stripePromise = loadStripe('pk_test_51NEOFoECf6bPRSSrDGHy8pOAU4U9GuOGwnfPFpiIbn4Yn3UhBD9n9HF3M4xM3xqmWXbQ0FUWpQjUz7uzWH5g5LEF00f5tVsM5P');

// below: ## below = DEPLOYED VARIABLES (live publishable key)
const stripePromise = loadStripe('pk_live_51NEOFoECf6bPRSSrOnbVFuzsJwyZdeILnjhZK881KExspx2EBEigONqeelrF6vjbec2sfj5jX3O5Hy7Wc6FYGwKo00LpvPucab');

// entire Auth0Provider tag below inserted aspiring for Auth0

// paste publishable key into par above
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Auth0Provider
            domain="dev-hboy3y8z0mexqsma.us.auth0.com"
            clientId="Ous73CvFmWLUiv3EsuPhC5rYnRN9RKdq"
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
        >
            <Elements stripe={stripePromise}>
                <App />
            </Elements>
        </Auth0Provider>
    </BrowserRouter>
);
