// Banana Componenty is the template for entity CRUD management.
import React from 'react';
import {ManageBanana} from "./Utility/ManageBanana";

export const BananaEdit = () => {

    const createNewPath = false; // set flag for ManageBanana to know how to operate (look up existing record or not, etc.)
    const displayId = (window.location.pathname).split('/')[3]; // string that exists after the third backslash; enables manageBanana to acquire correct as-is record

    return (
        <>
            <ManageBanana
                createNewPath={createNewPath}
                displayId={displayId}
            />
        </>
    );
}