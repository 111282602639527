import React, { useEffect, useState } from "react";
import CryptoMdl from "../../models/CryptoMdl";
import axios from 'axios';

export const Coin = () => {

    const [cryptoList, setCryptoList] = useState <CryptoMdl[] | null> (null);

    const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en';

    useEffect( () => {

        axios.get(url).then( (response) => {
            // console.log(response.data);
            setCryptoList(response.data);
        });
        }, []
    );

    return (
        <>

            {/*<p>hello gecko</p>*/}
            {/*{cryptoList ? cryptoList.map*/}
            {/*    (*/}
            {/*        (cryptoItem) =>*/}
            {/*        {*/}
            {/*            return (*/}
            {/*            <p>{cryptoItem.current_price}</p>*/}
            {/*            )*/}
            {/*        }*/}
            {/*    ): <p>got nothin for you</p>*/}
            {/*}*/}

            {/*all of above works, from curry*/}

            {/*pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.*/}
            <div className='pt-5'>
                <p>hello HERRING</p>
            {cryptoList ?
                <>
                    <p>some headline</p>
                    {cryptoList.map (cryptoItem => (
                        <p>{cryptoItem.current_price}</p>
                        ))
                    }

                </>
                :
                <p>got nothin for you</p>
            }
            </div>
        </>
    );
}