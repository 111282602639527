// Banana Componenty is the template for entity CRUD management.
// super important: the model attribute names herein MUST be precisely the same names as the SB-side receiving rqs model
// if not, the create/edit will fail, guaranteed.
class BananaRqs {
    name: string;
    email: string;
    locationType: string;
    category: string;
    veggie: string;
    dateString: string | undefined;
    timeString: string;
    timezoneString: string;
    bananaStatusCode: string;
    // following is for id-based drop-down attribute mgmt
    // bananaStatus: number | "";
    constructor(
        name: string
        , email: string
        , locationType: string
        , category: string
        , veggie: string
        , dateString: string | undefined
        , timeString: string
        , timezoneString: string
        , bananaStatusCode: string
        // following is for id-based drop-down attribute mgmt
        // , bananaStatus: number | ""
    ) {
            this.name = name;
            this.email = email;
            this.locationType = locationType;
            this.category = category;
            this.veggie = veggie;
            this.dateString = dateString;
            this.timeString = timeString;
            this.timezoneString = timezoneString;
            this.bananaStatusCode = bananaStatusCode;
        // following is for id-based drop-down attribute mgmt
        //     this.bananaStatus = bananaStatus;
        }
}
export default BananaRqs;