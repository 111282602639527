import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
import { HomePage } from './layouts/HomePage/HomePage';
import { Footer } from './layouts/NavbarAndFooter/Footer';
import { PlaydateList} from "./layouts/Playdate/PlaydateList/PlaydateList";
import { oktaConfig } from './lib/oktaConfig';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import LoginWidget from './Auth/LoginWidget';
import LoginButton from "./Auth/LoginButton";
import Profile from "./Auth/Profile";
import LogoutButton from "./Auth/LogoutButton";
import { SearchBooksPage } from './layouts/SearchBooksPage/SearchBooksPage';
import {NavbarReactBootstrap} from "./layouts/NavbarAndFooter/NavbarReactBootstrap";
import { BookCheckoutPage } from './layouts/BookCheckoutPage/BookCheckoutPage';
import { ShelfPage } from './layouts/ShelfPage/ShelfPage';
import { MessagesPage } from './layouts/MessagesPage/MessagesPage';
import { ManageLibraryPage } from './layouts/ManageLibraryPage/ManageLibraryPage';
import { ReviewListPage } from './layouts/BookCheckoutPage/ReviewListPage/ReviewListPage';
import { PaymentPage } from './layouts/PaymentPage/PaymentPage';
import {PlaydateNew} from './layouts/Playdate/PlaydateNew';
import {PlaydateRecord} from "./layouts/Playdate/PlaydateRecord";
import { Coin } from "./layouts/CoinCollection/Coin";
import { BananaListo } from "./layouts/Banana/BananaListo/BananaListo";
import {BananaRecord} from "./layouts/Banana/BananaRecord";
import {BananaEdit} from "./layouts/Banana/BananaEdit";
import {BananaNew} from "./layouts/Banana/BananaNew";


const oktaAuth = new OktaAuth(oktaConfig);

export const App = () => {

    const customAuthHandler = () => {
        history.push('/login');
    }

    const history = useHistory();

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <div className='d-flex flex-column min-vh-100'>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
                <NavbarReactBootstrap />
                    <div className='flex-grow-1'>
                        <Switch>
                            <Route path='/' exact>
                                <Redirect to='/home' />
                            </Route>

                            <Route path='/home'> <HomePage /> </Route>

                            <Route path='/login' render={() => <LoginWidget config={oktaConfig} />}/>

                            <Route path='/login/callback' component={LoginCallback} />

                            <Route path='/auth0login'> <LoginButton/> </Route>

                            <Route path='/auth0logout'> <LogoutButton/> </Route>

                            <Route path='/search'> <SearchBooksPage /> </Route>

                            <Route path='/reviewlist/:bookId'> <ReviewListPage/> </Route>

                            <Route path='/checkout/:bookId'> <BookCheckoutPage/> </Route>

                            <SecureRoute path='/shelf'> <ShelfPage/> </SecureRoute>
                            <SecureRoute path='/messages'> <MessagesPage/> </SecureRoute>
                            <SecureRoute path='/admin'> <ManageLibraryPage/> </SecureRoute>
                            <SecureRoute path='/fees'> <PaymentPage/> </SecureRoute>

                            <SecureRoute path='/playdate' exact> <PlaydateList /> </SecureRoute>

                            <SecureRoute path='/playdate/:playdateId' exact> <PlaydateRecord/> </SecureRoute>

                            <SecureRoute path='/createNew/playdate' exact> <PlaydateNew/> </SecureRoute>

                            <Route path='/coin'> <Coin/> </Route>

                            <SecureRoute path='/bananas/list' exact> <BananaListo /> </SecureRoute>
                            <SecureRoute path='/bananas/add' exact> <BananaNew/> </SecureRoute>
                            <SecureRoute path='/bananas/view/:bananaId' exact> <BananaRecord/> </SecureRoute>
                            <SecureRoute path='/bananas/edit/:bananaId' exact> <BananaEdit/> </SecureRoute>

                            <Route path='/profile'> <Profile/> </Route>
                        </Switch>
                    </div>
                <Footer />
            </Security>
        </div>
    );
}
