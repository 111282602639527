// oktaClassic essential file
export const oktaConfig = {

    // // below: DEVELOPMENT VARIABLES
    // // Account: everystepcounts1947@gmail.com;
    // issuer: `https://dev-62540411.okta.com/oauth2/default` ,
    // clientId: `0oaap9ywxw72B7DSJ5d7` ,

    // below = DEPLOYED VARIABLES
    // Account: everystepcounts1947@gmail.com;
    issuer: `https://dev-62540411.okta.com/oauth2/default` ,
    clientId: `0oaaqnwxm0Pr5cUvd5d7` ,


    redirectUri:`${window.location.origin}/login/callback` ,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: true,
}