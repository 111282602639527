// Banana Componenty is the template for entity CRUD management.
import React from "react";
import {Link} from "react-router-dom";
import BananaRsp from "../../../../models/BananaRsp";

export const BananaListoItem: React.FC<{
    item: BananaRsp
}> = (props) => {
    return (
        <div className='card mt-1 shadow p-2  mb-1 bg-body rounded'>
            <div className='row'>
                <div className='col-sm-5 ps-5'>
                    <h5 >{props.item.name}</h5>
                    <h6>{props.item.email}</h6>
                    <h4>{props.item.displayId}</h4>
                </div>

                <div className='col-sm-5 ps-5'>
                    <p>Location type: {props.item.locationType} <br></br>
                        Category: {props.item.category}
                    <br></br>
                    {props?.item.bananaStatusCodeDisplay} <br></br>
                        {props.item.dateString} {props.item.timeString} ({props.item.timezoneIdString})</p>
                </div>

                <div className='col-sm-2 d-flex justify-content-center align-items-center'>
                    <Link className='btn btn-md main-color text-white' to={ `/bananas/view/${props.item.displayId}` }>
                        View Details
                    </Link>
                </div>
            </div>
        </div>
    )
}