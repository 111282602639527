import {useEffect, useState} from "react";
import PlaydateMdl from "../../../models/PlaydateMdl";
import {Pagination} from "../../Utils/Pagination";
import {SpinnerLoading} from "../../Utils/SpinnerLoading";
import {PlaydateListItem} from "./Components/PlaydateListItem";
import {Link} from "react-router-dom";

// export const BananaListo: React.FC<{springbootlocation: String }> = (props) => {
export const PlaydateList = () => {

    // useState arrays
    const [itemList, setItemList] = useState <PlaydateMdl[]> ([]);

    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(5); // actually, setter never used.  This value is declared by developer: how many books per page?
    const [itemReturnedTotal, setItemReturnedTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState('');
    const [searchUrl, setSearchUrl] = useState('');
    const [categorySelection, setCategorySelection] = useState('All');

    // begin: useEffect for playdates
    useEffect( () => {
            const fetchItems = async () => {

                const baseUrl: string = `${process.env.REACT_APP_API}/playdates`;

                let url : string = '';

                if (searchUrl === '') {
                    url = `${baseUrl}?page=${currentPage -1}&size=${itemPerPage}`;
                } else {
                    let searchWithPage = searchUrl.replace('<pageNumber>', `${currentPage - 1}`); // if this skipped, pagination count figures will be bogus on search
                    url = baseUrl + searchWithPage;
                }

                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error('Something went terribly wrong (PlaydateList 01)');
                }

                const responseJson = await response.json();

                const responseData = responseJson._embedded.playdates;
                // console.log("responseData:")
                // console.log(responseData)

                setItemReturnedTotal(responseJson.page.totalElements);
                setTotalPages(responseJson.page.totalPages);

                const loadedItems : PlaydateMdl[] = [];

                for (const key in responseData) {
                    loadedItems.push({
                        id: responseData[key].id,
                        category: responseData[key].category,
                        eventName: responseData[key].eventName,
                        eventDescription: responseData[key].eventDescription,
                        veggie: responseData[key].veggie
                    });
                }

                setItemList(loadedItems);
                // console.log("loadedItems:")
                // console.log(loadedItems);
                setIsLoading(false);
            };

            fetchItems().catch( (error: any ) => {
                setIsLoading(false);
                setHttpError(error.message);
            })
            window.scrollTo(0,0) // this means: when useEffect runs, return the page scroll to the top most (and left most, although we dont' do left/right scrolling of course)
        }, [ // what's in brackets here means: whenever the variables listed here change, re-run useEffect
            currentPage
            , searchUrl
            ,itemPerPage // adding to resolve warning message
        // , props.springbootlocation // adding to resolve warning message
        ]
    );
    // end: useEffect for items

    // html for non-instant success scenarios
    if (isLoading) {
        return (
            <SpinnerLoading/>
        )
    }

    if (httpError) {
        return (
            <div className='container m-5'>
                <p>{httpError}</p>
            </div>
        )
    }

    const searchHandleChange = () => {
        setCurrentPage(1)
        if (search === '' ) {
            setSearchUrl('');
        } else {
            // setSearchUrl(`/search/findByTitleContaining?title=${search}&page=0&size=${itemPerPage}`)
            // setSearchUrl(`/search/findByTitleContaining?title=${search}&page=<pageNumber>&size=${itemPerPage}`)
            setSearchUrl(`/search/nqPlaydateEventNameOrDescriptionContains?incoming=${search}&page=<pageNumber>&size=${itemPerPage}`)
        }
        setCategorySelection('All'); // this means: reset the categorySelection to the default value of non-selection.  previously was "book category" but now it's 'all'
    }

    const categoryField = (value: string, categoryName: string) => {
        setCurrentPage(1);
        if (value.toLowerCase() === 'asparagus' ||
            value.toLowerCase() === 'broccoli' ||
            value.toLowerCase() === 'carrot' ||
            value.toLowerCase() === 'daikon' ||
            value.toLowerCase() === 'endive'
        ) {
            setCategorySelection(categoryName);
            // setSearchUrl(`/search/findByCategory?category=${value}&page=<pageNumber>&size=${itemPerPage}`)
            setSearchUrl(`/search/nqPlaydateByVeggieCat?veggie=${value}&page=<pageNumber>&size=${itemPerPage}`)
        } else {
            setCategorySelection('All');
            setSearchUrl(`?page=<pageNumber>&size=${itemPerPage}`)
        }
        // next line added by JRF to try to clear the search box when category affected.  This only has desired effect in conjunction with the search input also including value={search}
        setSearch('')
    }

    const indexOfLastItem: number = currentPage * itemPerPage; // id of last book being displayed on current page
    const indexOfFirstItem: number = indexOfLastItem - itemPerPage; // if of first book being displayed on current page
    let lastItem =
        itemPerPage * currentPage <= itemReturnedTotal ?
            itemPerPage * currentPage
            : itemReturnedTotal;
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        // pt-5 on main div below ensures that top of page content remains under the navBar; required on all pages.
        <div className='pt-5'>
            <div id='reactPageContainer'>
            <div className='container'>
                <h4 className='mt-5'>Refine List</h4>
                    <div className='container'>
                        <div className='row mt-2' id='searchControls'>

                            <div className='col-sm-6 mt-2' id='titleSearch'>
                                <div className='d-flex'  >
                                    <input
                                        className='form-control me-2'
                                        type='search'
                                        placeholder='Search'
                                        aria-labelledby='Search'
                                        onChange = {e => setSearch(e.target.value)}
                                        // next line added by JRF to try to clear the search box when category affected.  This only has desired effect in conjunction with the categoryField function also including setSearch('')
                                        value={search}
                                    />
                                    <button
                                        className='btn btn-outline-success'
                                        onClick={() => searchHandleChange()}>
                                        Search
                                    </button>
                                </div>
                            </div>

                            <div className='col-sm-1' id='searchTypeBreakWall'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <p className='fw-bold'>OR</p>
                                </div>
                            </div>

                            <div className='col-sm-5 mt-2'>
                                <div className='dropdown'>
                                    <button
                                        className='btn btn-secondary dropdown-toggle'
                                        type='button'
                                        id='dropdownMenuButton1'
                                        data-bs-toggle='dropdown'
                                        aria-expanded='false'>
                                        {categorySelection}
                                    </button>
                                    <ul
                                        className='dropdown-menu'
                                        aria-labelledby='dropdownMenuButton1'
                                    >
                                        <li className='dropdown-item'
                                            onClick={() => categoryField('All', 'All')}>All
                                        </li>

                                        <li className='dropdown-item'
                                            onClick={() => categoryField('asparagus', 'AAasparagus')}>AAasparagus
                                        </li>

                                        <li className='dropdown-item'
                                            onClick={() => categoryField('broccoli', 'BBbroccoli')}>BBbroccoli
                                        </li>

                                        <li className='dropdown-item'
                                            onClick={() => categoryField('carrot', 'CCcarrot')}>CCcarrot
                                        </li>
                                        <li className='dropdown-item'
                                            onClick={() => categoryField('daikon', 'DDdaikon')}>DDdaikon
                                        </li>

                                        <li className='dropdown-item'
                                            onClick={() => categoryField('endive', 'EEendive')}>EEendive
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='m-5'>
                            {/*<h3>*/}
                            {/*    Can't find what you are looking for?*/}
                            {/*</h3>*/}
                            <Link to='/createNew/playdate' type='button' className='btn main-color btn-lg px-4 me-md-2 fw-bold text-white'>
                                New Playdate
                            </Link>
                        </div>
                    </div>

                {/*<div>*/}
                { itemReturnedTotal > 0 ?
                    <>
                        <div className='mt-3'>
                            <h5>Total Matches: ({itemReturnedTotal})</h5>
                        </div>
                        <p>
                            {indexOfFirstItem +1} to {lastItem} of {itemReturnedTotal} items:
                        </p>
                        {itemList.map(item => (
                            <PlaydateListItem item={item} key={item.id}/>
                        ))}
                    </>
                    // placeholder: move pagination here when mind clear
                    :
                    <div>
                        <h3>Looks like there are no playdates for this search.</h3>
                    </div>
                }

                {totalPages > 1 && // double ampersand means: "then do this..." very confusing, but just go with it
                    <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate}/>
                }
                {/*</div>*/}

            </div>

        </div>
        </div>
    )
}