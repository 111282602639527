// Banana Componenty is the template for entity CRUD management.
import React from 'react';
import {ManageBanana} from "./Utility/ManageBanana";

export const BananaNew = () => {

    const createNewPath = true; // set flag for ManageBanana to know how to operate (look up existing record or not, etc.)
    const displayId = ''; // set displayId as empty item.  This is required by edit path, so BananaNew herein must send as well.

    return (
        <>
            <ManageBanana
                createNewPath={createNewPath}
                displayId={displayId}
            />
        </>
    );
}