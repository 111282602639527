import React from "react";
import PlaydateMdl from "../../../../models/PlaydateMdl";
import {Link} from "react-router-dom";
export const PlaydateListItem: React.FC<{item: PlaydateMdl}> = (props) => {
    return (
        <div className='card mt-3 shadow p-3 mb-3 bg-body rounded'>
            <div className='row g-0'>
                <div className='col-md-2'>

                    {/*below special design for images*/}

                    {/*desktop*/}
                    {/*<div className='d-none d-lg-block'>*/}
                    {/*    {props.item.img ?*/}
                    {/*        <img src={props.item.img}*/}
                    {/*             width='123'*/}
                    {/*             height='196'*/}
                    {/*             alt='itemShit'/>*/}
                    {/*        :*/}
                    {/*        <img src={require('../../../Images/BooksImages/item-luv2code-1000.png')}*/}
                    {/*             width='123'*/}
                    {/*             height='196'*/}
                    {/*             alt='itemShit'/>*/}
                    {/*    }*/}
                    {/*</div>*/}
                    {/*/!*mobile*!/*/}
                    {/*<div className='d-lg-none d-flex justify-content-center align-items-center'>*/}
                    {/*    {props.item.img ?*/}
                    {/*        <img src={props.item.img}*/}
                    {/*             width='123'*/}
                    {/*             height='196'*/}
                    {/*             alt='itemShit'/>*/}
                    {/*        :*/}
                    {/*        <img src={require('../../../Images/BooksImages/item-luv2code-1000.png')}*/}
                    {/*             width='123'*/}
                    {/*             height='196'*/}
                    {/*             alt='itemShit'/>*/}
                    {/*    }*/}
                    {/*</div>*/}

                </div>
                <div className='col-md-6'>
                    <div className='card-body'>
                        {/*<h5 className='card-title'>*/}
                        {/*    Item ID: {props.item.id}*/}
                        {/*</h5>*/}
                        <h1 className='card-title'>
                            {props.item.id}
                        </h1>
                        <h5 className='card-title'>
                            {props.item.eventName}
                        </h5>
                        <p>
                            {props.item.eventDescription}
                        </p>

                        <h5 className='card-text'>
                            Category: {props.item.category}
                        </h5>
                        {/*<p className='card-title'>*/}
                        {/*    Max kids: {props.item.maxCountKids}*/}
                        {/*</p>*/}

                        <h5 className='card-text'>
                            Veggie: {props.item.veggie}
                        </h5>
                    </div>
                </div>
                <div className='col-md-4 d-flex justify-content-center align-items-center'>
                    <Link className='btn btn-md main-color text-white' to={ `/playdate/${props.item.id}` }>
                        View Details
                    </Link>
                </div>
            </div>
        </div>
    )
}